<template>
  <b-card title="Detail DO Report">
    <b-modal
      id="promptDownload"
      ref="modal"
      v-model="promptDownload"
      title="Download"
      hide-footer
    >
      <strong v-if="dataToDownload.length <= 0">Loading Data...</strong>
      <strong v-else>Loading data finished, total data is {{ dataToDownload.length }}</strong>
      <div>
        <strong> Progress {{ progressDownload }} %</strong>
      </div>
      <div style="display: table; margin: 0 auto;">
        <b-button :disabled="dataToDownload.length < totalRows" variant="primary" style="margin-top: 20px">
          <download-excel :data="dataToDownload" :fields="filter" name="detailDeliveryOrders.xls" class="download-border">download</download-excel>
        </b-button>
      </div>
    </b-modal>
    <b-col cols="12">
      <b-row class="mb-1">
        <b-col cols="2">
          <b-button
            size="md"
            variant="primary"
            @click="$router.go(-1)"
            style="margin-top: 20px"
            >Back</b-button
          >
        </b-col>
        <b-col cols="2">
          <b-button variant="primary" style="margin-top: 20px" @click="openDownloadModal">
            Download
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="table-responsive">
          <b-table
            :fields="fields"
            :items="dataItem"
            :busy.sync="tableLoading"
            show-empty
          >
          </b-table>
        </b-col>
        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
  
</template>
  
  <script>
  import axios from '@/axios';
  import { dateFormat } from '@/utils/utils.js'
  import { mapActions, mapState, mapGetters } from 'vuex';
  
  export default {
    data() {
      return {
        item: {},
        status:'',
        fields: [
          { key: 'sapDo', label: 'DO', filterable: true, sortable: true },
          { key: 'itemModel', label: 'Model', filterable: true, sortable: true },
          { key: 'barcode', label: 'Barcode', filterable: true, sortable: true },
          { key: 'scanStatus', label: 'Status', filterable: true, sortable: true },
          { key: 'warrantyStatus', label: 'Warranty Status', filterable: true, sortable: true },
        ],
        tableLoading: true,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        dataToDownload:[],
        promptDownload: false,
        filter: {
          'SAP DO' : 'sapDo',
          'Model' :'itemModel',
          "Barcode" : 'barcode',
          'Status' : 'scanStatus',
          'Warranty Status' : "warrantyStatus"
        },
      }
    },
    mounted() {
      this.loadItem();
      this.getAllInventories({ 
        page: this.currentPage, 
        entry: this.perPage,
        sapDo : this.$route.params.sapDo,
      }).then((data) => this.processMetadata(data.metadata));
    },
    methods: {
      ...mapActions({
        getAllInventories: 'inventory/getAllInventories'
      }),
      dates(date){
        return dateFormat(date)
      },
      loadItem() {
        let sapDo = this.$route.params.sapDo;
      },
      processMetadata(metadata) {
        let { totalData } = metadata;
        this.totalRows = totalData;
        this.tableLoading = false;
      },
      onPageChange(page) {
        this.getAllInventories({ 
          page: page, 
          entry: this.perPage,
          sapDo : this.$route.params.sapDo,
        }).then((data) => this.processMetadata(data.metadata));
      },
      async openDownloadModal(){
        this.downPage = 0
        this.dataDownload = 0
        this.inventoryToDownload = []
        this.promptDownload=true;
        let page = 0
        var dataDownload=this.dataDownload
        while (dataDownload < this.totalRows) {
          console.info('total data now ',dataDownload,'all data ',this.totalRows)
          let dataProcess = []
          page++
          this.downPage = page  
          await this.getAllInventories({ 
            page: page, 
            entry: this.perPage,
            sapDo : this.$route.params.sapDo,
          }).then((res) => {
               // this.warrantyToDownload=data.data;
             dataProcess=res.data.map(order => ({
              ...order,
              barcode : order.scanStatus == "Scan" ? order.barcode : "",
            }))
             console.log("dataProcess", dataProcess);
             // console.log(dataProcess)
             // split into each item 
             this.dataToDownload.push(...dataProcess)
             dataDownload += dataProcess.length
             this.dataDownload = dataDownload
           });  
        }
      },
    },
    computed: {
      ...mapGetters({
        items: 'inventory/filterInventory',
      }),
      dataItem(){
        return this.items.map(order => ({
          ...order,
          barcode : order.scanStatus == "Scan" ? order.barcode : "",
        }))
      },
      progressDownload () {
        let downloaded = this.dataToDownload.length
        let dataToDownload = this.totalRows
        return Math.ceil((downloaded/dataToDownload)*(100))
      },
    }
  }
  </script>
  